import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';

function App() {
  const [socket, setSocket] = useState(null);
  const [token, setToken] = useState('');
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState('');
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [socket]);

  const connectSocket = () => {
    const newSocket = io('api.jpcom.pt', {
      auth: {
        token: token,
        platform: 'web'
      }
    });

    newSocket.on('connect', () => {
      console.log('Conectado ao servidor');
      setConnected(true);
      setSocket(newSocket);
    });

    newSocket.on('connect_error', (err) => {
      console.log('Erro de conexão:', err.message);
      setConnected(false);
    });

    newSocket.on('message', (data) => {
      setResponse(data);
    });
  };

  const sendMessage = () => {
    if (socket) {
      socket.emit('message', message);
    }
  };

  return (
    <div>
      <h1>Teste de Socket.IO</h1>
      <input
        type="text"
        value={token}
        onChange={(e) => setToken(e.target.value)}
        placeholder="Insira o token"
      />
      <button onClick={connectSocket}>Conectar</button>
      <br />
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Digite uma mensagem"
        disabled={!connected}
      />
      <button onClick={sendMessage} disabled={!connected}>Enviar</button>
      <p>Status: {connected ? 'Conectado' : 'Desconectado'}</p>
      <p>Resposta do servidor: {response}</p>
    </div>
  );
}

export default App;